import(/* webpackMode: "eager" */ "/vercel/path0/recruit_site_front/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/vercel/path0/recruit_site_front/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/vercel/path0/recruit_site_front/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/vercel/path0/recruit_site_front/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/recruit_site_front/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/recruit_site_front/src/app/globalicons.css");
import(/* webpackMode: "eager" */ "/vercel/path0/recruit_site_front/src/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/recruit_site_front/node_modules/next/font/google/target.css?{\"path\":\"src/constants/fonts.ts\",\"import\":\"Noto_Sans_JP\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--notosanjp\"}],\"variableName\":\"notoSanJp\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/recruit_site_front/node_modules/next/font/local/target.css?{\"path\":\"src/constants/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/AvenirNextLTPro-Bold.otf\",\"display\":\"swap\",\"variable\":\"--font-avenirNextBold\"}],\"variableName\":\"avenirNextBold\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/recruit_site_front/node_modules/next/font/local/target.css?{\"path\":\"src/constants/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/AvenirNextDemi.otf\",\"display\":\"swap\",\"variable\":\"--font-avenirNextDemi\"}],\"variableName\":\"avenirNextDemi\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/recruit_site_front/src/components/Atoms/AnimatedText.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/recruit_site_front/src/components/Organisms/Header.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/recruit_site_front/src/lib/apollo-wrapper.tsx")